import { ClerkApp, SignedIn } from "@clerk/remix"
import { rootAuthLoader } from "@clerk/remix/ssr.server"
import "@mantine/charts/styles.css"
import { ColorSchemeScript, MantineProvider } from "@mantine/core"
import "@mantine/core/styles.css"
import { DatesProvider } from "@mantine/dates"
import "@mantine/dates/styles.css"
import "@mantine/dropzone/styles.css"
import { Notifications } from "@mantine/notifications"
import "@mantine/notifications/styles.css"
import {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node"
import {
  Links,
  Meta,
  Outlet,
  redirect,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteLoaderData,
  useRouteError,
} from "@remix-run/react"
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { useTranslation } from "react-i18next"
import { useChangeLanguage } from "remix-i18next/react"
import { typedjson } from "remix-typedjson"

import { User } from "@kiosk/types/user"

import i18next from "@kiosk/front/i18next.server"

import { CLERK_THEME } from "./clerk-theme"
import { Title } from "./components/AppHeader/AppHeader"
import { clientConfig } from "./config"
import AppLayout from "./layout/AppLayout"
import ContributorLayout from "./layout/ContributorLayout"
import { queryClient } from "./lib/queryClient"
import { theme } from "./theme"
import { requireUser } from "./utils/auth.server"

export const meta: MetaFunction = () => [
  {
    charset: "utf-8",
    viewport: "width=device-width,initial-scale=1",
  },
]

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },

  {
    rel: "stylesheet",
    href:
      clientConfig.logo === "greenster"
        ? "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"
        : "https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap",
  },
]

export function shouldRevalidate() {
  return true
}

export const loader = async (args: LoaderFunctionArgs) => {
  const browserLocale = await i18next.getLocale(args.request)

  return rootAuthLoader(args, async ({ request }) => {
    const { userId } = request.auth

    const url = new URL(request.url)
    const pathname = url.pathname

    if (!userId && pathname !== "/sign-in") {
      return redirect("/sign-in")
    }

    try {
      const user = await requireUser(args)

      const locale = user.locale

      if (!user.isSignedUp && pathname !== "/onboarding") {
        return redirect("/onboarding")
      }

      return typedjson({ locale, user })
    } catch (err) {
      if (userId && pathname !== "/error") {
        return redirect("/error")
      }

      return typedjson({ locale: browserLocale })
    }
  })
}

export function ErrorBoundary() {
  const error = useRouteError()
  const { t } = useTranslation("common")

  captureRemixErrorBoundaryError(error)

  return (
    <html>
      <head>
        <title>Oh no!</title>

        <Meta />

        <Links />
      </head>

      <body>
        <div
          style={{
            width: "min(800px, 90%)",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <h1>{t("queryWrapper.error.title")}</h1>

          <p>{t("queryWrapper.error.message")}</p>
        </div>

        <Scripts />
      </body>
    </html>
  )
}

export function Layout({ children }: { readonly children: React.ReactNode }) {
  const res = useRouteLoaderData<typeof loader>("root")
  const locale = res?.locale ?? "fr"

  const { i18n } = useTranslation()

  useChangeLanguage(locale)

  return (
    <html data-mantine-color-scheme="light" dir={i18n.dir()} lang={locale}>
      <head>
        <Meta />

        <Links />

        <ColorSchemeScript />
      </head>

      <body>
        <MantineProvider theme={theme}>
          <Notifications />

          <DatesProvider settings={{ locale: i18n.language }}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools />

              {children}
            </QueryClientProvider>
          </DatesProvider>
        </MantineProvider>

        <ScrollRestoration />

        <Scripts />
      </body>
    </html>
  )
}

const routesTitles: Record<string, Title> = {
  "/companies": "companies",
  "/dashboard": "dashboard",
  "/dimensions": "dimensions",
  "/csrd": "csrd",
  "/settings": "settings",
  "/sources": "sources",
  "/surveys": "surveys",
  "/tasks": "tasks",
}

function App() {
  const data = useRouteLoaderData<typeof loader>("root")
  const pathname = useLocation().pathname
  const main = pathname.split("/")[1]
  const title = routesTitles[`/${main}`]

  if (pathname === "/sign-in") {
    return <Outlet />
  }

  if (pathname === "/congratulations") {
    return (
      <SignedIn>
        <Outlet />
      </SignedIn>
    )
  }

  if (pathname === "/onboarding") {
    return (
      <SignedIn>
        <Outlet context={{ user: data.user! as User & { token: string } }} />
      </SignedIn>
    )
  }

  if ("user" in data && data.user.role === "COMPANY_CONTRIBUTOR") {
    return <ContributorLayout title={title} user={data.user} />
  }

  if (pathname === "/error") {
    return <Outlet />
  }

  return (
    <SignedIn>
      <AppLayout
        pageTitle={title}
        user={data.user! as User & { token: string }}
      />
    </SignedIn>
  )
}

export default withSentry(ClerkApp(App, { appearance: CLERK_THEME }))
