import { Group, Text, ThemeIcon } from "@mantine/core"
import { NavLink } from "@remix-run/react"
import { useState } from "react"

import { NavBarItemProps } from "@kiosk/front/utils/constants/useNavbarItems"

import { FolderItem } from "./FolderItem"
import classes from "./NavBarItem.module.css"

export const NavBarItem = ({
  label,
  href,
  icon: Icon,
  subItems,
}: NavBarItemProps) => {
  const [openItem, setOpenItem] = useState<boolean>(true)

  if (!subItems) {
    return <NavItem href={href} icon={Icon} label={label} />
  }

  return (
    <>
      <FolderItem
        icon={Icon}
        label={label}
        openItem={openItem}
        setOpenItem={setOpenItem}
        subItems={subItems}
      />
      {openItem && (
        <ul>
          {subItems.map((subitem) => (
            <li key={"navbarSubItem" + subitem.label}>
              <NavItem
                href={subitem.href}
                label={subitem.label}
                subItems={subItems}
              />
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

const NavItem = ({ href, label, icon: Icon, subItems }: NavBarItemProps) => {
  return (
    <NavLink end={!!subItems} style={{ textDecoration: "none" }} to={href}>
      {({ isActive }) => (
        <Group
          bg={isActive ? "green.1" : "initial"}
          className={classes.NavBarItem}
          gap={12}
          p={12}
          style={{ borderRadius: "var(--mantine-radius-md)" }}
        >
          <ThemeIcon color={isActive ? "black" : "gray.5"} variant="white">
            {Icon && <Icon />}
          </ThemeIcon>
          <Text
            c={isActive ? "black" : "gray.5"}
            fw={isActive ? 600 : "normal"}
          >
            {label}
          </Text>
        </Group>
      )}
    </NavLink>
  )
}
