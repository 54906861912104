import { Tabs } from "@mantine/core"
import { useTranslation } from "react-i18next"

import SettingsTab from "./SettingTab"

interface SettingSubHeader {
  readonly activeTab: string
}

export default function SettingsSubHeader(props: SettingSubHeader) {
  const { activeTab } = props
  const { t } = useTranslation()

  return (
    <Tabs
      bottom={0}
      color="green"
      pl={20}
      pos="absolute"
      style={{ "--tab-border-color": "transparent" }}
      value={activeTab ?? "account"}
    >
      <Tabs.List bottom="transparent" defaultValue="account">
        <SettingsTab
          href="/settings/account/edit"
          isActive={activeTab === "account"}
          label={t("routes.account")}
          value="account"
        />
        <SettingsTab
          href="/settings/subjects"
          isActive={activeTab === "subjects"}
          label={t("routes.subjects")}
          value="subjects"
        />

        <SettingsTab
          href="/settings/users"
          isActive={activeTab === "users"}
          label={t("routes.users")}
          value="users"
        />

        <SettingsTab
          href="/settings/language"
          isActive={activeTab === "language"}
          label={t("routes.language")}
          value="language"
        />
      </Tabs.List>
    </Tabs>
  )
}
