import { Group, ThemeIcon, Text } from "@mantine/core"
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react"
import { useLocation } from "react-router-dom"

import { NavBarItemProps } from "@kiosk/front/utils/constants/useNavbarItems"

import classes from "./NavBarItem.module.css"

export interface FolderItemProps extends Omit<NavBarItemProps, "href"> {
  readonly openItem: boolean
  readonly setOpenItem: React.Dispatch<React.SetStateAction<boolean>>
}

export const FolderItem = ({
  label,
  icon: Icon,
  subItems,
  openItem,
  setOpenItem,
}: FolderItemProps) => {
  const location = useLocation()
  const isFolderActive =
    subItems &&
    subItems.some((subitem) => location.pathname.startsWith(subitem.href))

  return (
    <Group
      bg={isFolderActive ? "green.1" : "initial"}
      className={classes.NavBarItem}
      p={12}
      style={{ borderRadius: "var(--mantine-radius-md)" }}
    >
      <button
        className={classes.NavBarItem_folder}
        onClick={() => {
          setOpenItem(!openItem)
        }}
      >
        <Group justify="space-between" w="100%">
          <Group gap={12}>
            <ThemeIcon
              color={isFolderActive ? "black" : "gray.5"}
              variant="white"
            >
              {Icon && <Icon />}
            </ThemeIcon>
            <Text
              c={isFolderActive ? "black" : "gray.5"}
              fw={isFolderActive ? 600 : "normal"}
            >
              {label}
            </Text>
          </Group>
          {openItem ? (
            <ThemeIcon
              color={isFolderActive ? "black" : "gray.5"}
              variant="white"
            >
              <IconChevronDown />
            </ThemeIcon>
          ) : (
            <ThemeIcon
              color={isFolderActive ? "black" : "gray.5"}
              variant="white"
            >
              <IconChevronRight />
            </ThemeIcon>
          )}
        </Group>
      </button>
    </Group>
  )
}
